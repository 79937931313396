<template>
    <EstudianteEditar />
</template>
<script>
import EstudianteEditar from "@/components/EstudianteEditar.vue";
export default({
     components:{
        EstudianteEditar
    }
})
</script>